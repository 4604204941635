.MatchExercise .MatchAnswers {
  display: flex;
  justify-content: space-between;

  flex-wrap: wrap;

  .answer {
    width: 19rem;
    height: 7.5rem;

    text-align: center;

    margin-bottom: 1rem;

    font-size: 1.5rem;

    &.disabled {
      opacity: 0.6;
      cursor: auto;

      &.opaque {
        opacity: 0;
      }
    }
  }
}

.vertical .MatchExercise .MatchAnswers {
  .answer {
    width: 24rem;
  }
}