.MatchExercise .MatchCategories {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .category {
    position: relative;

    padding: 0;
    flex-direction: column;

    width: 36rem;
    height: 16rem;

    .image {
      position: absolute;

      width: 100%;
      height: 100%;

      background-size: cover;
      background-position: center;
    }

    .name {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: rgba(1, 1, 1, 0.6);

      width: 100%;
      height: 100%;

      position: absolute;
      font-size: 2rem;
    }
  }
}

.vertical .MatchExercise .MatchCategories {
  .category {
    width: 24rem;
    height: 18rem;
  }
}