.MatchExercise {
  position: relative;
  width: 100%;
  height: 100%;

  .MatchCategories {
    margin-bottom: 1rem;
  }

  .exercise-container {
    position: relative;

    .next-question {
      position: absolute;
      top: 10rem;
      width: 100%;
    }
  }
}

.vertical .MatchExercise {
  .MatchCategories {
    margin-bottom: 3rem;
  }
}