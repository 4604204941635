.SummaryScreen {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & > .Card {
    margin-bottom: 2rem;
  }

  .summary {
    max-height: 33rem;
    overflow-y: auto;

    .summary-row {
      display: flex;
      justify-content: space-around;

      width: 100%;

      margin-bottom: 1rem;

      .score {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;

          font-size: 2rem;
        }

        .name {
          padding-right: 5rem;
        }

        .points {
          font-weight: 700;
          white-space: nowrap;
        }
      }

      .Button {
        width: 50%;
        margin-left: 2rem;
      }
    }
  }

  .score-total {
    margin-top: 1rem;
    font-size: 3rem;

    p {
      margin: 1rem 0;
    }

    .name {
      padding-right: 2rem;
    }

    .points {
      font-weight: 700;
    }
  }

  .percentage-total {
    flex-wrap: wrap;
    padding: 2rem;

    .percentage-count, .pass-info {
      margin: 0;
      width: 100%;
    }

    .percentage-count {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    .pass-info {
      font-size: 3rem;
      font-weight: 700;
    }

  }

  &.compact {
    .header {
      display: none;
    }

    .summary {
      .Card {
        padding: 0.5rem;
      }
    }

    .score-total {
      padding: 0.25rem;
    }
  }
}

.vertical .SummaryScreen {
  .summary {
    max-height: 58rem;
  }
}