@import '../../css/common_vars';

.VotingExercise {
  .InstructionCard {
    margin-bottom: 4rem;
  }

  .VotingExercise-Answers {
    display: flex;

    flex-wrap: wrap;

    justify-content: space-between;
    margin-bottom: 1rem;

    .voting-smiley {
      transition: all ease-in-out 500ms;
      border: 1rem solid rgba(0, 0, 0, 0);

      width: 8rem;
      height: 8rem;
      margin-bottom: 4rem;

      &.selected {
        border-color: $color_main;
      }

      &.vote-6 {
        background-color: darken($color_success, 15%);

        &:hover {
          background-color: darken($color_success, 10%);
        }
      }

      &.vote-5 {
        background-color: $color_success;

        &:hover {
          background-color: lighten($color_success, 5%);
        }
      }

      &.vote-4 {
        background-color: $color_bright;

        &:hover {
          background-color: lighten($color_bright, 15%);
        }
      }

      &.vote-3 {
        background-color: lighten($color_highlight, 15%);

        &:hover {
          background-color: lighten($color_highlight, 10%);
        }
      }

      &.vote-2 {
        background-color: $color_highlight;

        &:hover {
          background-color: darken($color_highlight, 10%);
        }
      }

      &.vote-1 {
        background-color: $color_error;

        &:hover {
          background-color: darken($color_error, 10%);
        }
      }

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.vertical .VotingExercise {
  .InstructionCard {
    margin-bottom: 5rem;
  }

  .VotingExercise-Answers {

    .voting-smiley {
      width: 10rem;
      height: 10rem;

      margin-bottom: 4rem;
    }
  }
}