@import "../../css/common_vars";

.ExerciseInstructions {
  padding-top: 2rem;

  .Card {
    flex-direction: column;
    padding: 0;
    margin-bottom: 1rem;

    & > * {
      box-sizing: border-box;
      width: 100%;
    }

    .name {
      color: $color_main;
      background-color: $color_bright;

      font-size: 2rem;
      font-weight: 700;

      padding: 1rem;
    }

    .content {
      display: flex;

      .exercise-image {
        flex-shrink: 0;
        width: 30rem;
      }

      .instructions {
        width: 100%;
        padding: 2rem;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }
  }

  .Button {
    width: 25rem;
    transition: all 500ms ease-in-out;

    &.instructions-showing {
      background-color: shade($color_main, 25%);

      &:hover, &:active {
        background-color: shade($color_main, 10%);
      }
    }
  }
}

.ExerciseInstructionStep {
  width: 100%;

  display: flex;
  align-items: center;
  margin-bottom: 2rem;

  opacity: 1;
  transition: opacity 500ms ease-in-out;

  &.not-shown {
    opacity: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  img {
    min-width: 4rem;
    min-height: 4rem;
    width: 4rem;
    height: 4rem;
    margin-right: 1rem;
  }

  p {
    text-align: left;
    font-size: 1.75rem;
    display: inline-block;
    margin: 0;

    strong {
      color: $color_bright;
    }
  }
}

.vertical .ExerciseInstructions {
  .content {
    flex-direction: column;

    img.exercise-image {
      width: 100%;
    }
  }
}