@import '../css/common_vars.scss';

@keyframes pop-out {
  0% { transform: scale(0.5); animation-timing-function: ease-in-out; }
  60% { transform: scale(1.2); animation-timing-function: ease-in-out; }
  80% { transform: scale(0.8); animation-timing-function: ease-in-out; }
  100% { transform: scale(1); animation-timing-function: ease-in-out; }
}

.example-enter {
  opacity: 0.01;
}

.example-enter.example-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.example-leave {
  opacity: 1;
}

.example-leave.example-leave-active {
  opacity: 0.01;
  transition: opacity 500ms ease-in;
}

.example-appear {
  opacity: 0.01;
}

.example-appear.example-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fast-enter {
  opacity: 0.01;
}

.fast-enter.fast-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fast-leave {
  opacity: 1;
}

.fast-leave.fast-leave-active {
  opacity: 0.01;
  transition: opacity 250ms ease-in;
}

.fast-appear {
  opacity: 0.01;
}

.fast-appear.fast-appear-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fast-enter {
  opacity: 0.01;
}

.fast-enter.fast-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

html, body {
  margin: 0;
  height: 100%;
  width: 100%;
  position: fixed;

  overflow: hidden;
}

.hide {
  transition: opacity 500ms ease-in-out;
  opacity: 0;
}

body {
  transition: background-color 2000ms ease-in-out;

  font-family: $default-font-family;
}

.Sprint {
  & > .outer {
    & > .middle {
      background-color: $color_background;

      & > .inner:after {
        background: url('../images/logo-white.svg') no-repeat;
        background-position: 2em 5em;
        background-size: 50%;
      }
    }
  }

  .outer {
    display: table;
    position: absolute;
    width: 100%;
    height: 100%;

    //overflow: hidden;
  }

  .middle {

    transition: background-color 2000ms ease-in-out;
    z-index: -2;

    display: table-cell;
    vertical-align: middle;

    //overflow-y: hidden;
  }

  .inner {
    z-index: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;

    position: relative;

    width: 0;
    height: 0;

    &:after {
      content : "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity : 0.15;
      z-index: -1;
    }
  }

  .container {
    margin: auto;

    width: 100%;
  }

  .left {
    text-align: left;
  }

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }

  .vertical-helper {
    height: 100%;
    vertical-align: middle;
    display: inline-block;
  }

  .buttons-container {
    .button {
      margin-top: 0;
      width: 50%;


      border: white 5px solid;

      box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.5);
      cursor: pointer;

      p {
        margin: 0 0;
        padding: 0.3em;

        border-radius: 6px;
        background: linear-gradient(#E1497D, #D91B5C);

        color: white;
        font-size: 2.5em;
        font-weight: 700;
      }
    }
  }
}

.scrollable {
  scrollbar-color: $color_bright #f1f1f144;

  /* width */
  &::-webkit-scrollbar {
    width: 1rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f144;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color_bright;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: lighten($color_main, 5%);
  }
}

input, textarea {
  border: none;
  box-shadow: none;
}