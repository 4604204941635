$parachute-height-vertical: 20rem;
$parachute-height-horizontal: 25rem;

.ParachutesCard {
  position: relative;
  .Card {
    position: absolute;

    margin-left: 20%;
    width: 60%;
    padding: 3rem 1rem;
    font-size: 2rem;

    margin-top: -19rem;
    transition: opacity 500ms ease-in-out;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    &::before {
      content: " ";
      position: absolute;
      display: block;
      background-image: url('../img/parachute.svg');
      background-repeat: no-repeat;
      background-position-x: center;

      top: -$parachute-height-horizontal - 0.05rem;
      height: $parachute-height-horizontal;
      width: 100%;
    }
  }
}

.vertical {
  .ParachutesCard {
    .Card {
      margin-left: 0;
      width: 100%;

      &::before {
        top: -$parachute-height-vertical - 0.1rem;
        height: $parachute-height-vertical;
      }
    }
  }
}