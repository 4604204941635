@import "variables";

$side-border: 5px solid shade($color_background, 15%);

.Sprint {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  //background: shade($color_background, 25%);
  background: $color_background;

  padding-bottom: 40px;
  box-sizing: border-box;

  .SprintArea {
    position: relative;

    box-sizing: border-box;
    margin: 0;
    background: $color_background;
    z-index: 0;

    .side {
      position: absolute;
      background: $color_background;
      box-sizing: border-box;

      transition: opacity 500ms ease-in-out;
      opacity: 0;

      z-index: 100;

      &.show {
        opacity: 1;
      }

      &.left, &.right {
        height: 100vh;
        width: 50vw;
      }

      &.top, &.bottom {
        height: 50vh;
        width: 100vw;
      }

      &.left {
        left: -50vw;
        border-right: $side-border;
      }

      &.right {
        right: -50vw;
        border-left: $side-border;
      }

      &.top {
        top: -50vh;
        border-bottom: $side-border;
      }

      &.bottom {
        bottom: -50vh;
        border-top: $side-border;
      }
    }

    .inner-sprint-area {
      position: relative;
      width: 100%;
      height: 100%;


      &:after {
        content: " ";
        display: block;
        z-index: -1;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        background: url(../images/logo-white.svg) no-repeat;
        background-position-x: 2em;
        background-position-y: 5em;
        background-size: 50%;

        opacity: 0.2;
      }
    }
  }
}