@import '../../css/common_vars';

$card-color-text: lighten($color_main, 75%);
$card-color-bg: $color_main;
$card-height: 6rem;
$small-card-height: 3rem;
$small-card-height-vertical: 8rem;
$filler-length: 40rem;

.InstructionCard {
  min-height: $card-height;
  margin: 0 1rem 1.5rem;
  padding: 1rem 1rem 1rem 0;

  color: $card-color-text;
  background-color: $card-color-bg;

  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;

  position: relative;

  &:before {
    content: " ";
    display: block;

    z-index: -1;

    position: absolute;
    height: 100%;
    width: $filler-length;
    left: -$filler-length;

    background-color: $card-color-bg;
  }

  //.left-filler {
  //  z-index: -1;
  //
  //  position: absolute;
  //  height: $card-height;
  //  width: $filler-length;
  //  left: -$filler-length;
  //
  //  background-color: $card-color-bg;
  //}

  .text {
    .count {
      font-size: 1.3rem;
    }

    .content {
      font-size: 1.5rem;

      p {
        margin: 0.5rem;
      }

      p:first-child {
        margin-top: 0;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.small {
    min-height: $small-card-height;

    .left-filler {
      min-height: $small-card-height;
    }
  }
}

.vertical .InstructionCard {
  &.small {
    height: $small-card-height-vertical;

    .left-filler {
      height: $small-card-height-vertical;
    }
  }

  .text {
    line-height: 2.2rem;

    padding: 1rem 0;

    .content {
      font-size: 1.75rem;
    }
  }
}